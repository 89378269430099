
import { defineComponent } from 'vue';

import CurvyArrow from './helpers/CurvyArrow.vue';

type CssProps = {
  backgroundImage: string,
  backgroundPosition: string,
  backgroundSize: string,
};

export default defineComponent({
  name: 'HomeComponent',
  mounted() {
    document.title = 'ZS | Home';
  },

  components: {
    CurvyArrow,
  },

  computed: {
    portraitPath: () => require('@/assets/pictures/zach.jpg'),

    cssProps(): CssProps {
      const backgroundPosition = window.innerWidth < 450
        ? '0 -4vw'
        : '0 -3vh';

      return {
        backgroundImage: `url(${this.portraitPath})`,
        backgroundPosition,
        backgroundSize: '90%',
      };
    },
  },
});
