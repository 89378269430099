import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27b0d053"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "experience_container" }
const _hoisted_2 = { class: "name-term__container" }
const _hoisted_3 = { class: "left-align" }
const _hoisted_4 = { class: "role" }
const _hoisted_5 = {
  key: 0,
  class: "term-length left-align"
}
const _hoisted_6 = {
  key: 0,
  class: "description__container left-align"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createElementVNode("p", null, _toDisplayString(_ctx.name), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.role), 1)
      ]),
      (_ctx.termLength)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.termLength), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.descriptions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.descriptions, (description, i) => {
            return (_openBlock(), _createElementBlock("p", { key: i }, _toDisplayString(description), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}