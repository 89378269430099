
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CurvyArrow',
  props: {
    pointDown: Boolean,
  },
  computed: {
    svgStyles() {
      const transform = this.pointDown
        ? 'scaleX(-1) scaleY(-1)'
        : 'scaleX(-1) rotate(30deg)';

      const height = this.pointDown
        ? '1em'
        : '0.5em';

      return {
        height,
        minHeight: '15px',
        transform,
      };
    },
  }
});
