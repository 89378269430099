
import { defineComponent } from 'vue';

import ExperienceComponent from '../components/career/ExperienceComponent.vue';
import ProficienciesComponent from '../components/career/ProficienciesComponent.vue';

import { employers } from '../shared/employers';
import { educations } from '../shared/educations';
import { devProficiencies, ProficiencyType } from '../shared/proficiencies';
import useLabelDivSetup from '../shared/composables/useLabelDivSetup';
import useTouchLabelSetup from '@/shared/composables/useTouchLabelSetup';
import FloatingLabelComponent from '../components/helpers/FloatingLabelComponent.vue';
import CurvyArrow from '@/components/helpers/CurvyArrow.vue';

export default defineComponent({
  name: 'WebDev',

  components: {
    CurvyArrow,
    ExperienceComponent,
    ProficienciesComponent,
    FloatingLabelComponent
  },

  data: () => ({
    showTouchLabel: false,
  }),

  mounted() {
    document.title = 'ZS | Web Engineer';
  },

  setup: () => ({
    ...useLabelDivSetup(),
    ...useTouchLabelSetup(),
  }),

  computed: {
    ProficiencyType: () => ProficiencyType,
    devProficiencies: () => devProficiencies,
    employers: () => employers,
    educations: () => educations,
  },
});
